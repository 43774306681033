/*.grid {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
}

.grid > * {
    display: block;
    width: auto;
    margin: 16px;
}

li {
    list-style: none;
    padding: 0;
}

li > * {
    padding: 0;
}

.location-container {
    height: 200px;
    width: 300px;
    border-radius: 32px;
    padding: 32px;
    background-color: #0068ff20;
}*/

img.img-icon {
    height: 36px;
    width: 36px;
}

.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.disabled * {
    cursor: not-allowed;
}

.add-new-location-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.dialog-content > form {
    display: flex;
    flex-direction: column;
    min-width: 500px;
    padding: 24px;
}