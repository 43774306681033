main {
    width: 100%;
    display: flex;
    flex-direction: column;
}

main > *:nth-child(1) {
    min-height: 100vh;
}

main > * > * {
    width: 1100px;
    margin: 0 auto;
}

section:nth-child(2n) {
    background-color: #0000000F;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-columns-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
}

.flex-columns-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
}

.promo-image > img {
    width: 100%;
}

section:nth-child(1) {
    padding-top: 8px;
}

section:nth-child(1) > * {
    gap: 32px;
}
section:nth-child(1) > * > * {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

h1 {
    font-size: 32px;
}

h2 {
    font-size: 28px;
}

h3 {
    font-size: 24px;
}

p {
    font-size: 18px;
}

section:nth-child(1) p {
    font-size: 22px;
}

section:nth-child(1) {
    display: flex;
    flex-direction: column;
}

section:nth-child(1) > div.flex-row {
    flex-grow: 1;
}

section:nth-child(2) .articles {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    /* column-gap: 32px; */
}

section:nth-child(2) .articles > * {
    margin: 0 8px;
}

section:nth-child(2) .articles .bg-color {
    background-color: #cac7ff;
    border-radius: 8px;
    margin-top: 16px;
    padding: 4px 16px;
    flex-grow: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.text-align-center {
    text-align: center !important;
}

section:nth-child(2) .articles p {
    text-align: justify;
}

section:nth-child(2) .articles h3 {
    text-align: center;
}

section {
    padding: 32px 0 64px 0;
}

section h2 {
    text-align: center;
}

section:nth-child(3) p {
    font-size: 14px;
}

section:nth-child(3) table {
    margin-bottom: 32px;
}

section:nth-child(3) table {
    font-size: 18px;
}

section:nth-child(3) table > * > * {
    text-align: center;
    background-color: #dcdaff;
}

section:nth-child(3) table tr > * {
    padding: 20px 0;
}

section:nth-child(3) table tr:nth-child(2) > *:nth-child(1) {
    border-top-left-radius: 12px;
}

section:nth-child(3) table tr:nth-child(2) > *:last-child {
    border-top-right-radius: 12px;
}

section:nth-child(3) table tr:last-child > *:nth-child(1) {
    border-bottom-left-radius: 12px;
}

section:nth-child(3) table tr:last-child > *:last-child {
    border-bottom-right-radius: 12px;
}


/* section:nth-child(3) table tr:nth-child(1) > *:nth-child(1) { */
    /* background: transparent; */
/* } */

section:nth-child(3) table tr > *:nth-child(1) {
    background-color: #a8a4ff !important;
}

section:nth-child(3) table tr:nth-child(1) > * {
    background: transparent !important;
}

section:nth-child(3) table tr:nth-child(2n) > * {
    background-color: #d1cfff;
}

section:nth-child(3) table tr:nth-child(1) > *, section:nth-child(3) table tr > *:nth-child(1) {
    font-size: 20px;
}

section:nth-child(4) {
    background-size: cover;
    background-position-y: 40%;
    background-blend-mode: darken;
}

ul > li {
    padding: 3px 0;
    position: relative;
}

ul {
    list-style: none;
}

ul.custom li::before {
    position: absolute;
    content: "";
    background-color: #999;
    display: inline-block;
    width: 13px;
    height: 4px;
    border-radius: 6px;
    top: 14px;
    left: -20px;
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
}

.header > span {
    font-size: 26px;
    font-weight: bold;
}

.articles article {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.articles article h3 {
    margin-top: 12px;
    margin-bottom: 0;
}

.articles article > .icon-bg {
    background-color: #6c63ff;
    background-color: #afabff;
    padding: 12px;
    border-radius: 50%;
}

section > h2 {
    margin-bottom: 32px;
}

section:nth-child(4) {
    color: white;
}